import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import uuid from "react-uuid";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import StyledLink from "../styledLink";
import { resizeWidget } from "../../../redux/actions/contentActions";
import { openArticle } from "../../../redux/actions/contentActions";
import { logEventRecord } from "../../../redux/actions/chatActions";
import messageParent from "../../../lib/messageParent";
import { VIEW_ARTICLE_EVENT, VIEW_ORIGINAL_EVENT } from "../../../constants/events";
import {
  MIN_WIDTH_TO_EXPAND,
  WIDGET_SIZE_EXPANDED,
  EXPAND_MESSAGE
} from "../../../constants/widget";
import {
  ARTICLE_IS_PDF,
  SUPPORTED_ARTICLE_DOCUMENTS
} from "../../../constants/article";

const XtraStyledLink = styled(StyledLink)`
  display: inline-block;
  width: 100%;
  overflow-x: hidden;
`;

const StyledLinkText = styled.span`
  display: inline-block;
  vertical-align: middle;
  max-width: 90%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:hover {
    text-decoration: underline;
  }
`;

const iconStyle = {
  fontSize: "inherit",
  color: "inherit",
  verticalAlign: "middle"
};

function ArticleSource({ article, query, eventSubtype }) {
  const platform = useSelector((state) => state?.org?.platform);
  const parentOrigin = useSelector((state) => state?.org?.parentOrigin);
  const customizations = useSelector((state) => state?.org?.customizations);
  const windowDimensions = useSelector((state) => state?.content?.windowDimensions);

  const dispatch = useDispatch();

  const onMobilePlatform = platform?.startsWith("mobile");
  const canResizeWidget = !onMobilePlatform
    && windowDimensions?.width
    && windowDimensions.width >= MIN_WIDTH_TO_EXPAND;;

  const logEvent = (eventType) => {
    dispatch(logEventRecord(
      query,
      null,
      uuid(),
      eventType,
      eventSubtype,
      true,
      article.title
    ));
  };

  const expandWidget = () => {
    if (!customizations?.autoExpand || !canResizeWidget) {
      return;
    }
    messageParent(JSON.stringify({
      type: EXPAND_MESSAGE
    }), platform, parentOrigin);
    dispatch(resizeWidget(WIDGET_SIZE_EXPANDED));
  }

  const isPDF = article?.fileType === ARTICLE_IS_PDF;
  const isDocument = SUPPORTED_ARTICLE_DOCUMENTS.some(
    (t) => t === article?.fileType
  );
  const isExternal = typeof(article?.system) === "string"
    && typeof(article?.baseUrl) === "string";

  const viewArticle = (a) => {
    logEvent(VIEW_ARTICLE_EVENT);
    expandWidget();

    dispatch(openArticle({
      articleId: a.articleId || a.aid,
      meta: {
        ...a
      }
    }));
  };

  const titleText = (a) => isPDF
    ? `${a.translated_title || a.title} - Page ${a.page_number}`
    : a.translated_title || a.title;

  if (!article.system && article?.url && !isPDF && !isDocument) {
    return (
      <XtraStyledLink
        href={article.url}
        target="_blank"
        referrer="noreferrer"
        title={`Go to ${article.url}`}
        onClick={() => logEvent(VIEW_ORIGINAL_EVENT)}
      >
        <StyledLinkText>{titleText(article)}&nbsp;</StyledLinkText>
        <OpenInNewIcon sx={iconStyle} />
      </XtraStyledLink>
    );
  }
  if (article?.aid && (article?.version || isDocument || isExternal)) {
    return (
      <XtraStyledLink
        title={titleText(article)}
        onClick={() => viewArticle(article)}
      >
        <StyledLinkText>{titleText(article)}&nbsp;</StyledLinkText>
        <KeyboardArrowRightIcon sx={iconStyle} />
      </XtraStyledLink>
    );
  }
  return null;
}

export default ArticleSource;
