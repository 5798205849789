import uuid from "react-uuid";

import { authorizeSession, getOrgData, getOrgCustomizations } from "../../api";
import { parseCustomizations } from "../../lib/org/parseCustomizations";
import messageParent from "../../lib/messageParent";
import { logEventRecord } from "./chatActions";
import { DEFAULT_CHAT_CONFIG, DEFAULT_TOOLTIPS } from "../../constants/defaults";
import { BUSINESS_HOURS_FROM, BUSINESS_HOURS_TO, PICK_THE_LANGUAGE } from "../../constants/chat";
import { UTC_TIME_STRING_REGEX } from "../../constants/regex";
import { WEB, SUPPORTED_PLATFORMS } from "../../constants/platform";
import { LOCALIZATION_SUB_EVENT, SWITCH_LANGUAGE_EVENT } from "../../constants/events";

export const orgActions = {
  SET_PLATFORM: "SET_PLATFORM",
  INVALID_PLATFORM: "INVALID_PLATFORM",
  SET_ORG_SUBDOMAIN: "SET_ORG_SUBDOMAIN",
  SET_PARENT_ORIGIN: "SET_PARENT_ORIGIN",
  SET_PARENT_PATHNAME: "SET_PARENT_PATHNAME",
  SET_CUSTOMIZATION_PROFILE: "SET_CUSTOMIZATION_PROFILE",
  UPDATE_LANGUAGE: "UPDATE_LANGUAGE",

  FETCH_ORG_DETAILS_BEGIN: "FETCH_ORG_DETAILS_BEGIN",
  FETCH_ORG_DETAILS_SUCCESS: "FETCH_ORG_DETAILS_SUCCESS",
  FETCH_ORG_DETAILS_ERROR: "FETCH_ORG_DETAILS_ERROR",

  FETCH_ORG_CUSTOMIZATION_BEGIN: "FETCH_ORG_CUSTOMIZATION_BEGIN",
  FETCH_ORG_CUSTOMIZATION_SUCCESS: "FETCH_ORG_CUSTOMIZATION_SUCCESS",
  FETCH_ORG_CUSTOMIZATION_ERROR: "FETCH_ORG_CUSTOMIZATION_ERROR"
};

export const getOrgActions = (dispatch) => ({
  loadOrganizationDetails: (orgName) => dispatch(loadOrganizationDetails(orgName)),
  fetchOrgDetails: (orgName) => dispatch(fetchOrgDetails(orgName)),
  fetchOrgCustomizations: (orgName) => dispatch(fetchOrgCustomizations(orgName)),
  setPlatform: (platform) => dispatch(setPlatform(platform))
});

export const loadOrganizationDetails = (subdomain) => {
  return async (dispatch, getState) => {
    const reduxState = getState();
    const conversationId = reduxState?.chat?.chatRecordId;
    const profile = reduxState?.org?.profile;
    const platform = reduxState?.org?.platform;
    const parentOrigin = reduxState?.org?.parentOrigin

    await authorizeSession(subdomain, conversationId, platform, profile, parentOrigin);

    dispatch(fetchOrgDetails(subdomain));
    dispatch(fetchOrgCustomizations(subdomain));
  };
};

export const fetchOrgDetails = (subdomain) => {
  return async (dispatch, getState) => {
    const parseBusinessHours = (val, defaultVal) => {
      if (typeof(val) !== "string" || !UTC_TIME_STRING_REGEX.test(val)) {
        console.warn("Readyly ChatAssist: invalid business hours, using defaults!");
        return defaultVal;
      }
      return val;
    };
    dispatch({ type: orgActions.FETCH_ORG_DETAILS_BEGIN });
    try {
      const reduxState = getState();
      const response = await getOrgData(subdomain, {
        orgName: subdomain,
        conversationId: reduxState?.chat?.chatRecordId,
        parentOrigin: reduxState?.org?.parentOrigin,
        profile: reduxState?.org?.profile,
        platform: reduxState?.org?.platform
      });
      const payload = {
        id: response?.org_data?._id,
        name: response?.org_data?.orgName,
        ticketingSystem: response?.org_data?.ticketing?.platform ?? "",
        ticketing: {
          senderEmail: response?.org_data?.ticketing?.sender_email ?? "",
          supportEmail: response?.org_data?.ticketing?.support_email ?? ""
        },
        agentStatus: response?.org_data?.org_agent_status,
        validateEndUser: response?.org_data?.validate_enduser,
        businessHours: {
          daysOff: Array.isArray(response?.org_data?.business_hours?.weeklyDaysOff)
            ? response?.org_data?.business_hours?.weeklyDaysOff
            : [],
          from: parseBusinessHours(
            response?.org_data?.business_hours?.from,
            BUSINESS_HOURS_FROM
          ),
          to: parseBusinessHours(
            response?.org_data?.business_hours?.to,
            BUSINESS_HOURS_TO
          )
        },
        liveChatConfig: response?.org_data?.liveChatConfig ?? {},
        enableSmartflows: response?.org_data?.enableSmartflows ?? false,
        amazon_connect: {
          region: response?.org_data?.amazon_connect?.region,
          instanceId: response?.org_data?.amazon_connect?.instanceId,
          contactFlowId: response?.org_data?.amazon_connect?.contactFlowId,
          endpoint: response?.org_data?.amazon_connect?.endpoint,
          contentTypes: response?.org_data?.amazon_connect?.contentTypes,
          duration: response?.org_data?.amazon_connect?.duration,
        }
      };
      const validationCallback = (v) => v === null || v === undefined;
      if (Object.values(payload).some(validationCallback)) {
        throw new Error("Readyly ChatAssist: malformed/missing org details");
      }
      dispatch({
        type: orgActions.FETCH_ORG_DETAILS_SUCCESS,
        payload
      });
    } catch (error) {
      const reduxState = getState();
      console.warn(error.message);
      messageParent(JSON.stringify({
        type: "_ft_error_",
        payload: {
          message: "Malformed/missing organization settings!"
        }
      }), reduxState?.org?.platform, reduxState?.org?.parentOrigin);
      dispatch({type: orgActions.FETCH_ORG_CUSTOMIZATION_ERROR });
    }
  };
};

export const fetchOrgCustomizations = (subdomain) => {
  return async (dispatch, getState) => {
    dispatch({ type: orgActions.FETCH_ORG_CUSTOMIZATION_BEGIN });
    try {
      const reduxState = getState();
      const platform = reduxState?.org?.platform;
      const profile = reduxState?.org?.profile;
      const response = await getOrgCustomizations(subdomain, profile, {
        orgName: subdomain,
        conversationId: reduxState?.chat?.chatRecordId,
        parentOrigin: reduxState?.org?.parentOrigin,
        platform,
        profile
      });
      const data = response?.data;
      const assetUrls = {
        fonts: data?.fontsUrl,
        images: data?.imagesUrl
      };
      const customizations = parseCustomizations(data);

      const validationCallback = (v) => v === null || v === undefined;
      if (Object.values(customizations).some(validationCallback)
        || Object.values(assetUrls).some(validationCallback)
        ) {
        throw new Error(`Readyly ChatAssist: malformed/missing customizations!`);
      }

      const parentOrigin = reduxState?.org?.parentOrigin;

      const callback = (o) => o === parentOrigin;
      if (platform === WEB && !customizations?.validOrigins?.some(callback)) {
        throw new Error(`Readyly ChatAssist: invalid origin ${parentOrigin}`);
      }

      customizations.chatConfig = {
        ...DEFAULT_CHAT_CONFIG,
        ...customizations.chatConfig
      };
      customizations.tooltips = {
        ...DEFAULT_TOOLTIPS,
        ...customizations.tooltips
      };
      dispatch({
        type: orgActions.FETCH_ORG_CUSTOMIZATION_SUCCESS,
        payload: {
          customizations,
          assetUrls
        }
      });
    } catch(error) {
      const reduxState = getState();
      console.warn(error.message);
      messageParent(JSON.stringify({
        type: "_ft_error_",
        payload: {
          message: "Malformed/missing customization settings!"
        }
      }), reduxState?.org?.platform, reduxState?.org?.parentOrigin);
      dispatch({ type: orgActions.FETCH_ORG_CUSTOMIZATION_ERROR })
    }
  };
};

export const setOrgSubdomain = (orgName) => {
  return {
    type: orgActions.SET_ORG_SUBDOMAIN,
    payload: orgName
  };
};

export const setCustomizationProfile = (profile) => {
  return {
    type: orgActions.SET_CUSTOMIZATION_PROFILE,
    payload: profile
  };
};

export const setPlatform = (platform) => {
  return (dispatch, getState) => {
    if (getState()?.org?.platform) {
      console.warn("Readyly ChatAssist: platform can not be changed after initialization!")
      return;
    }

    for (const p of SUPPORTED_PLATFORMS) {
      if (p !== platform) {
        continue;
      }
      dispatch({
        type: orgActions.SET_PLATFORM,
        payload: platform
      });
      return;
    }

    console.warn(`Readyly ChatAssist: platform "${platform}" is not supported!`);
    dispatch({ type: orgActions.INVALID_PLATFORM });
  };
};

export const setParentOrigin = (origin) => {
  return {
    type: orgActions.SET_PARENT_ORIGIN,
    payload: origin
  };
};

export const setParentPathname = (pathname) => {
  return {
    type: orgActions.SET_PARENT_PATHNAME,
    payload: pathname
  };
};

export const updateLanguage = (lang, override = false) => {
  return (dispatch, getState) => {
    if (typeof(lang) !== "string" || !lang.length) {
      return;
    }

    lang = lang.toLowerCase();

    const reduxState = getState();
    const localization = reduxState?.org?.customizations?.localizationConfig;
    const supportedLangs = localization?.supported;

    if (!supportedLangs && !override) {
      return;
    }

    for (const l in supportedLangs) {
      if (lang === l || lang === supportedLangs[l].toLowerCase()) {
        lang = l;
        break;
      }
    }

    if (lang === PICK_THE_LANGUAGE) {
      lang = localization?.default ?? "";
    }

    dispatch(logEventRecord(
      "",
      null,
      uuid(),
      SWITCH_LANGUAGE_EVENT,
      LOCALIZATION_SUB_EVENT,
      true,
      lang
    ));

    dispatch({
      type: orgActions.UPDATE_LANGUAGE,
      payload: lang
    });
  };
};
