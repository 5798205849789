import { useSelector } from "react-redux";
import styled from "styled-components";

import { READYLY_ICON } from "../../../../../constants/defaults";
import useLocalization from "../../../../../hooks/localization";

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  padding: 0.5rem 0;
  justify-content: center;
  align-items: center;
`;

const Link = styled.a`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
  line-height: 1.3;
  font-size: ${({ theme }) => theme.sm};
  font-style: normal;
  text-decoration: none;
  font-weight: 600;
  color: #37393B;
  cursor: pointer;
  user-select: none;
`;

const Logo = styled.img`
  width: 1rem;
  height: 1rem;
`;

function Banner() {
  const assetUrls = useSelector((state) => state?.org?.assetUrls);
  const tooltips = useSelector(
    (state) => state?.org?.customizations?.tooltips?.footer
  );

  const { localizeText } = useLocalization();

  return (
    <Container>
      <Link href="https://www.readyly.com" target="_blank">
        <span>{localizeText(tooltips?.banner)}</span>
        <Logo src={`${assetUrls?.images}/${READYLY_ICON}`} />
      </Link>
    </Container>
  );
}

export default Banner;
