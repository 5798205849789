import { useSelector } from "react-redux";
import styled from "styled-components";
import { useTransition } from "@react-spring/web";

import Reply from "./Reply";
import Conversation from "./Conversation";
import Banner from "./Banner";
import { getConfigValueByPersona } from "../../../../lib/chat/getConfigValue";

const ChatContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  row-gap: 0.25rem;
  width: 100%;
  height: 100%;
  padding: ${({ hasBanner }) => hasBanner
    ? "0 0.5rem"
    : "0.5rem 0.5rem 0"};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 1rem;
`;

function Chat({
  isWidgetOpen,
  handleUserQuery,
  showNotification,
  handleUserAttachment
}) {
  const conversationBanner = useSelector(
    (state) => state?.org?.customizations?.conversationBanner
  );
  const personas = useSelector(
    (state) => state?.chat?.endUser?.personas
  );

  const bannerContent = getConfigValueByPersona(
    conversationBanner,
    "content",
    personas
  );
  const bannerConfig = getConfigValueByPersona(
    conversationBanner,
    "config",
    personas
  );
  const hasBanner =
    (typeof(bannerContent) === "string" && bannerContent?.length > 0)
    || (typeof(bannerContent) === "object" && bannerContent !== null);

  const transition = useTransition(hasBanner, {
    from: { scaleY: 0, opacity: 0 },
    enter: { scaleY: 1, opacity: 1 },
    leave: { scaleY: 0, opacity: 0 }
  });

  return (
    <ChatContainer hasBanner={hasBanner}>
      {transition((styles, hasBanner) => (
        hasBanner && (
          <Banner styles={styles} content={bannerContent} config={bannerConfig} />
      )))}
      <Conversation isWidgetOpen={isWidgetOpen} />
      <Reply
        handleUserQuery={handleUserQuery}
        showNotification={showNotification}
        handleUserAttachment={handleUserAttachment} />
    </ChatContainer>
  )
}

export default Chat;
