import { useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import uuid from "react-uuid";
import { animated } from "@react-spring/web"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import { WebSocketContext } from "../../../../../App";
import {
  setBotTyping,
  workflowExecutionStart,
  logEventRecord
} from "../../../../../redux/actions/chatActions";
import { pSBC } from "../../../../../lib/pSBC";
import {
  LINEAR_GRADIENT_REGEX,
  VALID_URL_REGEX
} from "../../../../../constants/regex";
import {
  EXECUTE_WORKFLOW_EVENT,
  WORKFLOW_SUB_EVENT
} from "../../../../../constants/events";
import useLocalization from "../../../../../hooks/localization";

const Container = styled(animated.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.25rem;
  align-self: center;
  width: 100%;
  padding: 0.5rem;
  ${({ theme, config }) => {
    if (config?.backgroundColor) {
      return `background-color: ${config?.backgroundColor}`;
    }
    if (LINEAR_GRADIENT_REGEX.test(config?.linearGradient)) {
      return `background: ${config?.linearGradient}`;
    }
    return `background: ${pSBC(0.8, theme.primaryColor)}`
  }};
  line-height: 1.25;
  color: ${({ theme, config }) =>
    config?.fontColor || theme.fontColor};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme, config }) => config?.fontSize || theme.sm};
  ${({ config, executingWorkflow }) => {
    if (config?.workflow?.id) {
      return executingWorkflow
        ? "cursor: not-allowed;"
        : "cursor: pointer;"
    }
    if (config?.linkTo?.length > 0) {
      return "cursor: pointer;"
    }
    return "";
  }}
  transform-origin: top center;
`;

const Text = styled.div`
  box-sizing: border-box;
  overflow: hidden;
  max-width: 90%;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const iconStyle = {
  alignSelf: "center",
  fontSize: "inherit",
  color: "inherit",
  verticalAlign: "middle"
};

function Banner({ content, config, styles }) {
  const orgDetails = useSelector((state) => state?.org?.details);
  const endUser = useSelector((state) => state?.chat?.endUser);
  const currentWorkflow = useSelector((state) => state?.chat?.currentWorkflow);

  const { sendJsonMessage } = useContext(WebSocketContext);
  const dispatch = useDispatch();
  const { localizeText } = useLocalization();

  const link = typeof(config?.linkTo) === "string"
    ? config.linkTo
    : "";
  const hasLink = VALID_URL_REGEX.test(link);

  const hasWorkflow = config?.workflow?.id;
  const executingWorkflow = currentWorkflow?.id;

  const beginWorkflowExecution = () => {
    if (executingWorkflow) {
      return;
    }

    const data = config?.workflow;

    sendJsonMessage({
      action: "stateMachine",
      orgid: orgDetails?.id,
      workflowid: data?.id,
      endUser,
    });
    dispatch(workflowExecutionStart(
      data?.id,
      data?.version,
      data?.title,
      data?.type
    ));
    dispatch(setBotTyping(true));
    dispatch(logEventRecord(
      null,
      null,
      uuid(),
      EXECUTE_WORKFLOW_EVENT,
      WORKFLOW_SUB_EVENT,
      true,
      `Workflow Name: ${data?.title || "<Missing Title>"}`,
      null,
      {
        id: data?.id,
        type: data?.type,
        title: data?.title,
        version: data?.version
      }
    ));
  };

  const handleClick = () => {
    if (hasWorkflow) {
      beginWorkflowExecution();
      return;
    }
    if (!hasLink) {
      return;
    }
    Object.assign(document.createElement('a'), {
      target: '_blank',
      rel: 'noopener noreferrer',
      href: config?.linkTo,
    }).click();
  };

  return (
    <Container
      style={styles}
      config={config}
      executingWorkflow={executingWorkflow}
      onClick={handleClick}
    >
      <Text>{localizeText(content)}</Text>
      {hasLink && (
        <KeyboardArrowRightIcon sx={{iconStyle}} />
      )}
    </Container>
  );
}

export default Banner;