import { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import uuid from "react-uuid";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import Loader from "./Loader";
import Card, {
  CardContainer,
  CardTitle,
  CardContent,
  CardFooter
} from "../../../../common/Card";
import StyledScrollbarDiv from "../../../../common/scrollbar";
import BackButton from "../../../../common/backButton";
import messageParent from "../../../../../lib/messageParent";
import useInfiniteScroll  from "../../../../../hooks/infiniteScroll";
import {
  resizeWidget,
  openArticle,
  closeTopic,
  clearSearchResults,
  openTopic
} from "../../../../../redux/actions/contentActions";
import { logEventRecord } from "../../../../../redux/actions/chatActions";
import {
  MIN_WIDTH_TO_EXPAND,
  WIDGET_SIZE_EXPANDED,
  EXPAND_MESSAGE
} from "../../../../../constants/widget";
import {
  RESOURCE_SECTION_SUB_EVENT,
  VIEW_ARTICLE_EVENT
} from "../../../../../constants/events";
import useLocalization from "../../../../../hooks/localization";

const Container = styled(StyledScrollbarDiv)`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0 0.125rem 0 0;
  overflow-y: auto;
`;

const Header = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 0.75rem;
  padding: 0.5rem 0.75rem;
  border-bottom: 1px solid ${({ theme }) => theme.veryLightGrey};
`;

const StyledBackButton = styled(BackButton)`
  padding: 0.125rem 0;
`;

const StyledCardContainer = styled(CardContainer)`
  padding-left: 0.25rem;
  cursor: default;
`;

function formatArticles(articles) {
  return Object.keys(articles ?? {})
    .map((aid) => ({
      aid,
      ...articles?.[aid]
    }));
}

function Articles({ topic, articles, hasMore, isLoading, loadMore }) {
  const platform = useSelector((state) => state?.org?.platform);
  const parentOrigin = useSelector((state) => state?.org?.parentOrigin);
  const customizations = useSelector((state) => state?.org?.customizations);
  const windowDimensions = useSelector((state) => state?.content?.windowDimensions);

  const dispatch = useDispatch();

  const { localizeTooltips } = useLocalization();

  const canResizeWidget =
    windowDimensions?.width && windowDimensions.width >= MIN_WIDTH_TO_EXPAND;
  const tooltips = localizeTooltips(customizations?.tooltips?.resources);

  const articlesArray = useMemo(() => formatArticles(articles), [articles]);

  const { rootRef, lastElementRef } = useInfiniteScroll(
    articlesArray.length,
    hasMore,
    isLoading,
    loadMore
  );

  const goBack = () => {
    if (!topic?.id) {
      dispatch(clearSearchResults());
      return;
    }
    if (!topic?.parent || topic?.parent === "0") {
      dispatch(closeTopic());
      return;
    }
    dispatch(openTopic(topic.parent));
  };

  const expandWidget = () => {
    if (!customizations?.autoExpand || !canResizeWidget) {
      return;
    }
    messageParent(JSON.stringify({
      type: EXPAND_MESSAGE
    }), platform, parentOrigin);
    dispatch(resizeWidget(WIDGET_SIZE_EXPANDED));
  };

  const loadArticle = (a) => {
    expandWidget();
    dispatch(openArticle({
      articleId: a.articleId || a.aid,
      meta: {
        ...a
      }
    }));
    const title = topic?.name
      ? `Topic Name: ${topic?.name}\nTitle: ${a?.title}`
      : `Title: ${a?.title}`;
    dispatch(logEventRecord(
      null,
      null,
      uuid(),
      VIEW_ARTICLE_EVENT,
      RESOURCE_SECTION_SUB_EVENT,
      true,
      title
    ));
  };

  const renderArticle = (a, index, array) => {
    if (!a?.aid || !a?.title) {
      return;
    }

    const isLastElement = index + 1 === array.length;

    return (
      <Card
        key={a.aid}
        title={a.translated_title || a.title}
        showIcon={true}
        {...(isLastElement
          ? { ref: lastElementRef }
          : {})}
        clickHandler={() => loadArticle(a)} />
    );
  };

  return (
    <Container ref={rootRef}>
      <Header>
        <StyledBackButton onClick={goBack}>
          <KeyboardArrowLeftIcon
            sx={{ fontSize: "inherit", color: "inherit" }} />
          &nbsp;{tooltips?.back}
        </StyledBackButton>
        {topic?.name?.length > 0 && (
          <StyledCardContainer>
            <CardTitle>{topic.name}</CardTitle>
            {topic?.desc?.length > 0 && (
              <CardContent>{topic.desc}</CardContent>
            )}
            {!Number.isNaN(topic?.count) && (
              <CardFooter>
                {topic.count}&nbsp;
                {topic.count === 1
                  ? tooltips?.article
                  : tooltips?.articles}
              </CardFooter>
            )}
          </StyledCardContainer>
        )}
      </Header>
      {articlesArray.map(renderArticle)}
      {isLoading && (
        <>
          <Loader width="75%" />
          {articlesArray?.length === 0 && (
            <>
              <Loader width="90%" />
              <Loader width="60%" />
            </>
          )}
        </>
      )}
    </Container>
  );
}

export default Articles;
