import {
  getArticleContent,
  getArticlesByTopic,
  getExternalArticleContent,
  getTopics,
  searchArticles,
  titleSeachArticles
} from "../../api";
import { processArticleMeta } from "../../lib/processArticleMeta";
import { urlify } from "../../lib/urlify";
import { ARTICLE_IS_PDF, PUBLISHED_STATUS, SUPPORTED_ARTICLE_DOCUMENTS } from "../../constants/article";
import { END_USER, PUBLIC_ACCESS, ANONYMOUS_USER } from "../../constants/user";
import { processWpPost } from "../../lib/wordpress/processWpPost";
import { TITLE_SEARCH_KB_SYSTEM, WEB_KB_SYSTEM } from "../../constants/chat";

export const contentActions = {
  SET_INTENT: "SET_INTENT",

  OPEN_CHAT: "OPEN_CHAT",

  OPEN_ARTICLE_BEGIN: "OPEN_ARTICLE_BEGIN",
  OPEN_ARTICLE_SUCCESS: "OPEN_ARTICLE_SUCCESS",
  OPEN_ARTICLE_ERROR: "OPEN_ARTICLE_ERROR",

  RESIZE_WIDGET: "RESIZE_WIDGET",
  SET_WINDOW_DIMENSIONS: "SET_WINDOW_DIMENSIONS",
  SET_WIDGET_ORIENTATION: "SET_WIDGET_ORIENTATION",

  LOAD_SIMILARITY_BEGIN: "LOAD_SIMILARITY_BEGIN",
  LOAD_SIMILARITY_SUCCESS: "LOAD_SIMILARITY_SUCCESS",
  LOAD_SIMILARITY_ERROR: "LOAD_SIMILARITY_ERROR",

  CLEAR_SIMILARITY: "CLEAR_SIMILARITY",

  OPEN_CHAT_SECTION: "OPEN_CHAT_SECTION",
  OPEN_RESOURCES_SECTION: "OPEN_RESOURCES_SECTION",

  LOAD_TOPICS_BEGIN: "LOAD_TOPICS_BEGIN",
  LOAD_TOPICS_SUCCESS: "LOAD_TOPICS_SUCCESS",
  LOAD_TOPICS_ERROR: "LOAD_TOPICS_ERROR",

  OPEN_TOPIC: "OPEN_TOPIC",
  CLOSE_TOPIC: "CLOSE_TOPIC",

  LOAD_ARTICLES_BY_TOPIC_BEGIN: "LOAD_ARTICLES_BY_TOPIC_BEGIN",
  LOAD_ARTICLES_BY_TOPIC_SUCCESS: "LOAD_ARTICLES_BY_TOPIC_SUCCESS",
  LOAD_ARTICLES_BY_TOPIC_ERROR: "LOAD_ARTICLES_BY_TOPIC_ERROR",

  UPDATE_SEARCH_QUERY: "UPDATE_SEARCH_QUERY",
  CLEAR_SEARCH_RESULTS: "CLEAR_SEARCH_RESULTS",

  SEARCH_ARTICLES_BEGIN: "SEARCH_ARTICLES_BEGIN",
  SEARCH_ARTICLES_SUCCESS: "SEARCH_ARTICLES_SUCCESS",
  SEARCH_ARTICLES_ERROR: "SEARCH_ARTICLES_ERROR"
};

export const getContentActions = (dispatch) => ({
  similarity: (content) => dispatch(similarity(content)),
  openArticle: (article) => dispatch(openArticle(article)),
  loadTopics: () => dispatch(loadTopics()),
  loadArticlesByTopic: (topicId, perPage = 20) => dispatch(loadArticlesByTopic(topicId, perPage)),
  searchKnowledge: (perPage = 20) => dispatch(searchKnowledge(perPage))
});

export const openChatSection = () => {
  return {
    type: contentActions.OPEN_CHAT_SECTION
  };
};

export const openResourcesSection = () => {
  return {
    type: contentActions.OPEN_RESOURCES_SECTION
  };
};

export const openChat = () => {
  return {
    type: contentActions.OPEN_CHAT,
  };
};

export const resizeWidget = (widgetSize) => {
  return {
    type: contentActions.RESIZE_WIDGET,
    payload: widgetSize
  };
};

export const setWindowDimensions = (dimensions) => {
  return {
    type: contentActions.SET_WINDOW_DIMENSIONS,
    payload: dimensions
  };
};

export const setWidgetOrientation = (orientation) => {
  return {
    type: contentActions.SET_WIDGET_ORIENTATION,
    payload: orientation
  }
}

export const clearSimilarity = () => {
  return {
    type: contentActions.CLEAR_SIMILARITY
  };
};

export const openTopic = (topicId) => {
  return {
    type: contentActions.OPEN_TOPIC,
    payload: topicId
  };
};

export const closeTopic = () => {
  return {
    type: contentActions.CLOSE_TOPIC
  };
};

export const updateSearchQuery = (query) => {
  return {
    type: contentActions.UPDATE_SEARCH_QUERY,
    payload: { query }
  };
};

export const clearSearchResults = () => {
  return {
    type: contentActions.CLEAR_SEARCH_RESULTS
  };
};

export const openArticle = (article) => {
  return async (dispatch, getState) => {
    const state = getState();
    const orgDetails = state?.org?.details;
    const openedArticles = state?.content?.openedArticles;
    const endUser = state?.chat?.endUser;

    dispatch({
      type: contentActions.OPEN_ARTICLE_BEGIN,
      payload: article
    });

    try {
      for (const a of openedArticles) {
        if (article?.articleId !== a?.articleId) {
          continue;
        }
        dispatch({
          type: contentActions.OPEN_ARTICLE_SUCCESS,
          payload: {
            article: {
              ...a,
              // replacing meta to update page number for pdfs
              meta: {
                ...article?.meta,
                formattedText: a?.meta?.formattedText
              }
            },
            isNew: false
          }
        });
        return;
      }

      const isDocument = SUPPORTED_ARTICLE_DOCUMENTS.some(
        (t) => t === article?.meta?.fileType
      );

      let response = {};

      if (!isDocument) {
        const readylySession = {
          orgName: state?.org?.subdomain,
          conversationId: state?.chat?.chatRecordId,
          platform: state?.org?.platform,
          profile: state?.org?.profile,
          parentOrigin: state?.org?.parentOrigin
        };

        switch(article.meta.system) {
          case "wordpress":
            response = await getExternalArticleContent(
              orgDetails?.id,
              article.meta.system,
              article.meta.baseUrl,
              article.articleId,
              JSON.stringify(endUser?.personas || []),
              readylySession
            );
            if (response?.data?.formattedText?.length > 0) {
              response.data.formattedText =
                processWpPost(response.data.formattedText);
            }
            break;
          case "shopify":
          case "zendesk":
          case "salesforce":
          case "freshdesk":
          case WEB_KB_SYSTEM:
            response = await getExternalArticleContent(
              orgDetails?.id,
              article.meta.system,
              article.meta.baseUrl,
              article.articleId,
              JSON.stringify(endUser?.personas || []),
              readylySession
            );
            break;
          default:
            response = await getArticleContent(orgDetails?.id, {
              articleId: article.articleId,
              version: article.meta.version,
              status: PUBLISHED_STATUS
            }, readylySession);
            if (response?.data?.formattedText?.length > 0) {
              response.data.formattedText =
                urlify(response?.data?.formattedText);
            }
            break;
        }
      }

      dispatch({
        type: contentActions.OPEN_ARTICLE_SUCCESS,
        payload: {
          article: {
            ...article,
            meta: {
              ...article.meta,
              formattedText: response?.data?.formattedText
            }
          },
          isNew: true
        }
      });
    } catch(error) {
      console.error(`Readyly ChatAssist: Failed to load article: ${error}`);
      dispatch({
        type: contentActions.OPEN_ARTICLE_ERROR,
        payload: article
      });
    }
  }
};

export const setIntent = (articles) => {
  if (!Array.isArray(articles)) {
    return {
      type: contentActions.SET_INTENT,
      payload: []
    };
  }
  const filterCallback = (a) =>
    a?.aid && a?.version && a?.target === END_USER && a?.access === PUBLIC_ACCESS;
  articles = articles.filter(filterCallback);
  return {
    type: contentActions.SET_INTENT,
    payload: articles.slice(0, 5)
  };
}

export const loadSimilarityStart = () => {
  return {
    type: contentActions.LOAD_SIMILARITY_BEGIN
  };
};

export const similarity = (content) => {
  return (dispatch, getState) => {
    try {
      const query = getState()?.chat?.query || "";

      const similarity = content?.similarity || [];
      const filterCallback = (a) => !!a?.meta;
      const mapCallback = (a) => a.meta;
      const processedArticles = processArticleMeta(
        similarity.filter(filterCallback).map(mapCallback),
        10
      );
      dispatch({
        type: contentActions.LOAD_SIMILARITY_SUCCESS,
        payload: {
          query,
          processedArticles
        }
      });
    } catch (error) {
      dispatch({ type: contentActions.LOAD_SIMILARITY_ERROR });
    }
  };
};

export const loadTopics = () => {
  return async (dispatch, getState) => {
    const reduxState = getState();
    const orgDetails = reduxState?.org?.details;
    const endUser = reduxState?.chat?.endUser;

    try {
      dispatch({ type: contentActions.LOAD_TOPICS_BEGIN });

      const kbSystem = reduxState?.org?.customizations?.kbSystem;

      if (kbSystem?.name === TITLE_SEARCH_KB_SYSTEM || kbSystem?.name === WEB_KB_SYSTEM) {
        dispatch({
          type: contentActions.LOAD_TOPICS_SUCCESS,
          payload: { topics: [] }
        });
        return;
      }

      const response = await getTopics(
        orgDetails?.id,
        kbSystem?.name,
        kbSystem?.baseUrl,
        JSON.stringify(endUser?.personas || []),
        {
          orgName: reduxState?.org?.subdomain,
          conversationId: reduxState?.chat?.chatRecordId,
          platform: reduxState?.org?.platform,
          profile: reduxState?.org?.profile,
          parentOrigin: reduxState?.org?.parentOrigin
        }
      );

      const topics = Array.isArray(response?.data?.topics)
        ? response?.data?.topics
        : [];

      dispatch({
        type: contentActions.LOAD_TOPICS_SUCCESS,
        payload: { topics: topics }
      });
    } catch (error) {
      console.warn("Readyly ChatAssist: failed to get topics!");
      dispatch({
        type: contentActions.LOAD_TOPICS_ERROR
      });
    }
  };
};

export const loadArticlesByTopic = (topicId, perPage = 20) => {
  return async (dispatch, getState) => {
    const reduxState = getState();
    const orgDetails = reduxState?.org?.details;
    const articlesByTopic = reduxState?.content?.articlesByTopic;
    const endUser = reduxState?.chat?.endUser;

    if (articlesByTopic?.[topicId]?.isLastPage) {
      return;
    }

    try {
      dispatch({
        type: contentActions.LOAD_ARTICLES_BY_TOPIC_BEGIN,
        payload: { topicId }
      });

      const kbSystem = reduxState?.org?.customizations?.kbSystem;
      const nextPage = (articlesByTopic?.[topicId]?.page ?? 0) + 1;

      if (kbSystem?.name === TITLE_SEARCH_KB_SYSTEM || kbSystem?.name === WEB_KB_SYSTEM) {
        dispatch({
          type: contentActions.LOAD_ARTICLES_BY_TOPIC_SUCCESS,
          payload: {
            topicId,
            articles: [],
            isLast: true
          }
        });
        return;
      }

      const response = await getArticlesByTopic(
        orgDetails?.id,
        kbSystem?.name,
        kbSystem?.baseUrl,
        topicId,
        nextPage,
        perPage,
        JSON.stringify(endUser?.personas || []),
        {
          orgName: reduxState?.org?.subdomain,
          conversationId: reduxState?.chat?.chatRecordId,
          platform: reduxState?.org?.platform,
          profile: reduxState?.org?.profile,
          parentOrigin: reduxState?.org?.parentOrigin
        }
      );
      const articles = Array.isArray(response?.data?.articles)
        ? response.data.articles.map((a) => a?.meta)
        : [];

      dispatch({
        type: contentActions.LOAD_ARTICLES_BY_TOPIC_SUCCESS,
        payload: {
          topicId,
          articles: processArticleMeta(articles, perPage),
          isLast: articles?.length < perPage
        }
      });
    } catch(error) {
      console.warn("Readyly ChatAssist: failed to get articles by topic!");
      dispatch({
        type: contentActions.LOAD_ARTICLES_BY_TOPIC_ERROR,
        payload: { topicId }
      });
    }
  };
};

export const searchKnowledge = (perPage = 20) => {
  return async (dispatch, getState) => {
    const reduxState = getState();
    const kbSystem = reduxState?.org?.customizations?.kbSystem;

    if (kbSystem?.name === TITLE_SEARCH_KB_SYSTEM || kbSystem?.name === WEB_KB_SYSTEM) {
      dispatch(titleSearchKnowledge());
      return;
    }

    const orgDetails = reduxState?.org?.details;
    const searchQuery = reduxState?.content?.searchQuery;
    const endUser = reduxState?.chat?.endUser;

    if (!searchQuery) {
      return;
    }

    try {
      dispatch({
        type: contentActions.SEARCH_ARTICLES_BEGIN
      });

      const nextPage = 1; // we always get one page per search
      const readylySession = {
        orgName: reduxState?.org?.subdomain,
        conversationId: reduxState?.chat?.chatRecordId,
        platform: reduxState?.org?.platform,
        profile: reduxState?.org?.profile,
        parentOrigin: reduxState?.org?.parentOrigin
      };

      const response = await Promise.all([
        searchArticles(
          orgDetails?.id,
          kbSystem?.name,
          kbSystem?.baseUrl,
          searchQuery,
          nextPage,
          perPage,
          JSON.stringify(endUser?.personas || []),
          readylySession
        ),
        titleSeachArticles(
          orgDetails?.id,
          searchQuery,
          JSON.stringify(endUser?.personas || []),
          readylySession
        )
      ]);
      const external = Array.isArray(response?.[0]?.data?.articles)
        ? response[0].data.articles.map((a) => a?.meta)
        : [];
      const titleSearch = Array.isArray(response?.[1]?.data?.articles)
        ? response[1].data.articles.map((a) => a?.meta)
        : [];
      const articles = [...titleSearch, ...external];

      dispatch({
        type: contentActions.SEARCH_ARTICLES_SUCCESS,
        payload: {
          articles: processArticleMeta(articles, perPage + titleSeachArticles.length),
        }
      });
    } catch(error) {
      console.warn("Readyly ChatAssist: failed to get search results!");
      dispatch({
        type: contentActions.SEARCH_ARTICLES_ERROR
      });
    }
  };
};

export const titleSearchKnowledge = () => {
  return async (dispatch, getState) => {
    const reduxState = getState();
    const orgDetails = reduxState?.org?.details;
    const searchQuery = reduxState?.content?.searchQuery;
    const endUser = reduxState?.chat?.endUser;

    if (!searchQuery) {
      return;
    }

    try {
      dispatch({
        type: contentActions.SEARCH_ARTICLES_BEGIN
      });

      const response = await titleSeachArticles(
        orgDetails?.id,
        searchQuery,
        JSON.stringify(endUser?.personas || []),
        {
          orgName: reduxState?.org?.subdomain,
          conversationId: reduxState?.chat?.chatRecordId,
          platform: reduxState?.org?.platform,
          profile: reduxState?.org?.profile,
          parentOrigin: reduxState?.org?.parentOrigin
        }
      );
      const articles = Array.isArray(response?.data?.articles)
        ? response.data.articles.map((a) => a?.meta)
        : [];

      dispatch({
        type: contentActions.SEARCH_ARTICLES_SUCCESS,
        payload: {
          articles: processArticleMeta(articles, 10),
        }
      });
    } catch(error) {
      console.warn("Readyly ChatAssist: failed to get title search results!");
      dispatch({
        type: contentActions.SEARCH_ARTICLES_ERROR
      });
    }
  };
};
